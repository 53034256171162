import AOS from 'aos';

jQuery( document ).ready(function($) {

  // Animations
  AOS.init({
    duration: 2000,
    once: true,
  });
  
  // Popups functions
  const openPopup = (trigger) => {
    const thisTrigger = trigger
    const target = thisTrigger.getAttribute('href')
    document.querySelector(target).classList.add('active')
    playPause()
    const playThisOneTrack = trigger.getAttribute('data-target-track')
    playThisOne(playThisOneTrack)
    // if (trigger.getAttribute('data-target-track') == 1){
    // } else {
    //   playThisOne(0)
    // }
  }

  const closePopup = (close) => {
    const thisTrigger = close
    const thisPopup = thisTrigger.closest('.popup')
    const thisAudio = thisPopup.querySelector('audio')
    thisPopup.classList.remove('active')
    thisAudio.pause()
  }

  // Popups events
  const popupTriggers = document.querySelectorAll('.popup-trigger')
  const popupClose = document.querySelectorAll('#popup-default .close')
  popupTriggers.forEach(trigger => $(trigger).click(() => openPopup(trigger)))
  popupClose.forEach(close => $(close).click(() => closePopup(close)))


  // Player
  const currentTitle = document.querySelector('.player .title span')
  const currentAudio = document.querySelector('.player audio')
  const currentAudioSource = document.querySelector('.player audio source')
  const sondDescription = document.querySelector('.description')
  const playButton = document.querySelector('.player .play')
  const prevButton = document.querySelector('.player .prev')
  const nextButton = document.querySelector('.player .next')
  const playButtonIcon = document.querySelector('.player .play i')
  const progressBar = document.querySelector('.progress')
  const totalTime = document.querySelector('.total')
  const elapsedTime = document.querySelector('.elapsed')
  const tracklist = [
    {
      name: "Antsy",
      path: "./dist/audio/antsy.mp3",
      description: "ANTSY IS A SONG FOR ANYONE WHO FEELS LIKE THEY WANT TO MAKE A CHANGE IN THEIR LIFE, BUT DON’T KNOW HOW TO. IT’S THAT MOMENT WHEN YOU REALIZE YOU’VE HAD ENOUGH OF WHATEVER BAD SHIT HAS BEEN GOING ON AND YOU NEED TO SWITCH IT UP AND START FRESH. IT COULD LITERALLY BE ANYTHING FROM RELATIONSHIPS TO HEALTH TO THE INTERNET TO SCHOOL TO WORK, ETC. WHATEVER IT IS THAT MAKES YOU ANTSY, WE’VE ALL BEEN THERE."
    },
    {
      name: "Into My Body",
      path: "./dist/audio/imb.mp3",
      description: "I wrote Into My Body at the beginning of the summer after getting back from almost <span>6</span> months of touring. For the first time in a while, I wasn’t surrounded by chaos and constant brain stimulation, so I was kind of left to me and my own thoughts. I got stuck in my head and was completely disconnected from reality. I’m an intense person, so this feeling got very extreme. I wanted to write a song about how I was having an out of body experience, but it became a song about getting back into my body. It’s also definitely, maybe a masturbation anthem lol"
    },
    {
      name: "Kickflip",
      path: "./dist/audio/kf.mp3",
      description: `The day I wrote Kickflip, I was nearing the end of the writing process for the EP, and I realized that I had covered so many pieces of myself, but was missing the part of me that is unbothered as fuck. Kickflip pinpoints that part of the night where you are feeling on top of the world. You feel hot, you want to stir the pot up a bit, you want to entertain, be entertained, be spontaneous, and you feel like nothing in the world can get you out of that mood. I don’t even know how to do a "kick flip," but that’s the unbothered energy I wanted to harness!`
    },
  ];
  const tracksNumber = tracklist.length


  // Player Functions
  const convertTime = (time) => {    
    let mins = Math.floor(time / 60);
    if (mins < 10) {
      mins = '0' + String(mins);
    }
    let secs = Math.floor(time % 60);
    if (secs < 10) {
      secs = '0' + String(secs);
    }
    if(isNaN(mins)){
      return '00' + ':' + '00';
    } else{
      return mins + ':' + secs;
    }
  }

  const updateDuration = () => {
    setTimeout(() => {
      totalTime.innerHTML = convertTime(currentAudio.duration)
    }, 500)
  }

  
  const playPause = () => {
    if(currentAudio.paused){
      currentAudio.play()
      playButtonIcon.classList.remove('fa-play')
      playButtonIcon.classList.add('fa-pause')
    } else{
      currentAudio.pause()
      playButtonIcon.classList.remove('fa-pause')
      playButtonIcon.classList.add('fa-play')
    }
    currentAudio.addEventListener('canplaythrough', updateDuration, false);
  }

  const prev = () =>{
    const currentTrack = parseInt(document.querySelector('.player audio').getAttribute('data-track-number'))
    let prevTrack = currentTrack - 1
    if (prevTrack < 0) {
      prevTrack = tracksNumber - 1
    } 
    document.querySelector('.player audio').setAttribute('data-track-number', prevTrack)
    currentTitle.innerHTML = tracklist[prevTrack].name
    currentAudioSource.setAttribute('src', tracklist[prevTrack].path)
    sondDescription.innerHTML = tracklist[prevTrack].description
    currentAudio.load();
    // updateDuration()
    playPause();
  }

  const next = () =>{
    const currentTrack = parseInt(document.querySelector('.player audio').getAttribute('data-track-number'))
    let nextTrack = currentTrack + 1
    if (nextTrack > tracksNumber - 1) {
      nextTrack = 0
    }
    document.querySelector('.player audio').setAttribute('data-track-number', nextTrack)
    currentTitle.innerHTML = tracklist[nextTrack].name
    currentAudioSource.setAttribute('src', tracklist[nextTrack].path)
    sondDescription.innerHTML = tracklist[nextTrack].description
    currentAudio.load();
    // updateDuration()
    playPause();
  }

  const playThisOne = (number) => {
    document.querySelector('.player audio').setAttribute('data-track-number', number)
    currentTitle.innerHTML = tracklist[number].name
    currentAudioSource.setAttribute('src', tracklist[number].path)
    sondDescription.innerHTML = tracklist[number].description
    currentAudio.load();
    // updateDuration()
    playPause();
  }

  $('#audio').on('timeupdate', function() {
    progressBar.style.width = `${this.currentTime / this.duration * 100}%`
    elapsedTime.innerHTML = convertTime(this.currentTime)
  });
  
  // Player events
  playButton.addEventListener('click', playPause)
  prevButton.addEventListener('click', prev)
  nextButton.addEventListener('click', next)

  // const spotifyEmbed = document.querySelector('iframe')
  // const spotifyEmbedSrc = spotifyEmbed.getAttribute('src')
  // $('#popup-spotify').click(function(){
  //   spotifyEmbed.setAttribute('src', spotifyEmbedSrc)
  // })
  // $('#popup-spotify .close').click(function(){
  //   $('#popup-spotify').removeClass('active')
  //   spotifyEmbed.setAttribute('src', '')
  // })

  $('#stars').mapster({
    fillOpacity: 0
  });

  $('.go-to-presave').click(function(){
    window.open('https://UPSAHL.lnk.to/EPPresave', '_blank');
  })
});